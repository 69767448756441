interface StartPageProps {
    input: string;
    onInputChange: (input: string) => void;
}

export default function StartPage({ input, onInputChange }: StartPageProps) {
    return (
        <div className="flex flex-col gap-10 justify-center items-center ease-in-out duration-300">
            <div className="text-center flex flex-col gap-4">
                <h1 className="text-6xl text-mistyRose font-openSans font-bold">Was könnte das Passwort sein? 🤔</h1>
                <p className="text-lg text-mistyRose/40">Pssst! 🤫 Ich habe einen kleinen Tipp! Die Frage hast du anfangs sehr gerne gestellt.</p>
            </div>
            <div className="w-full flex justify-center">
                <input
                    type="text"
                    value={input}
                    onChange={(e) => onInputChange(e.target.value)}
                    placeholder="Passwort hier eintragen..."
                    className="w-1/2 px-8 bg-white/5 ring-white/15 py-4 rounded-[8px] outline-none text-white placeholder:text-white/40 text-lg font-openSans ease-in-out duration-300 ring-2 focus:ring-mistyRose focus:ring-opacity-50 animate-pulse focus:animate-none"
                />
            </div>
        </div>
    );
}
