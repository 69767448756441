import { useState } from "react";
import StartPage from "./pages/Start";
import RewardsPage from "./pages/Rewards";

function App() {
  const PASSWORD = "Hast du Spaß?";
  
  const [input, setInput] = useState("");

  const onInputChange = (input: string) => {
    setInput(input);
  }

  const isValidPassword = () => input.toLowerCase() === PASSWORD.toLowerCase();

  return (
    <div className="w-screen h-screen flex justify-center items-center bg-smokeyBlack">
      { isValidPassword()
        ? <RewardsPage />
        : <StartPage input={input} onInputChange={onInputChange} />}
    </div>
  );
}

export default App;
