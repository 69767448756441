import RewardCard from "../components/RewardCard";

type Reward = {
    title: string;
    img: string;
    description: string;
}

export default function RewardsPage() {

    const rewards: Reward[] = [
        {
            title: "300€ Vabali Gutschein",
            img: "https://www.vabali.de/wp-content/uploads/2023/08/vabali-neutrales-Logo-Website.svg",
            description: "Entspannung für Nino! 🧘"
        },
        {
            title: "Eat The Film Gutschein",
            img: "https://p16-sign-useast2a.tiktokcdn.com/tos-useast2a-avt-0068-euttp/4624b3cd844ef315b35efea00a60ff26~c5_100x100.jpeg?lk3s=a5d48078&x-expires=1704373200&x-signature=%2BEwFmUBg0inaGef3nO3CwclhXCE%3D",
            description: "Du bekommst das gleiche Essen wie im Film! Mit dabei: Reise + Wochenende in Hamburg. 🍽️"
        },
        {
            title: "Cocktail Kurs",
            img: "https://www.cocktail-kurs.com/wp-content/uploads/2020/03/cocktailkurs-heidelberg-jga.jpg",
            description: "Wir machen unsere eigenen Cocktails! 🍹"
        }
    ];

    return (
        <div className="ease-in-out duration-300 flex flex-col gap-10 items-center justify-center">
            <div className="text-center flex flex-col gap-2">
                <h1 className="text-6xl">🎁🎄</h1>
            </div>
            <div className="flex flex-col md:flex-row gap-4">
                {rewards.map(item => (
                    <RewardCard
                        key={item.title}
                        title={item.title}
                        img={item.img}
                        description={item.description}
                    />
                ))}
            </div>
        </div>
    );
}
