import './reward-card-style.css';

interface RewardCardProps {
    title: string;
    img: string;
    description: string;
}

export default function RewardCard({ title, img, description }: RewardCardProps) {
    return (
        <div className="flip-card">
            <div className="flip-card-inner">
                <div className="flip-card-front bg-white/10">
                    <div className="flex justify-center items-center h-full w-full">
                        <span className="text-6xl">🔮</span>
                    </div>
                </div>
                <div className="flip-card-back bg-white/5">
                    <div className="flex flex-col gap-2 items-center justify-center h-full w-full">
                        <img src={img} width="100" height="100" alt="Card" />
                        <h3 className="text-2xl text-mistyRose font-semibold">
                            {title}
                        </h3>
                        <p className="text-lg text-white/25">
                            {description}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
